import styled from "styled-components"

export const InternetPhoneTrendsWrapper = styled.section`
    background-color: #144056;
    #chart{
        max-width: 313px;
        .chartContainer{
            height: 0;
            width: 100%;
            padding-top: 100%;
            .radialChart{
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                @media (min-width: 992px){

                }
                svg{
                    transform: scale(1.35);
                }
            }
        }
    }
`
