import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const ComponentName = ({ logoImages }) => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: {relativeDirectory: {eq: "country_report_focus/payment_logos"}}) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
            name
          }
        }
      }
    }
  `)
  var logosArray = [];
  if (logoImages) {
    for (let i = 0; i < data.allFile.edges.length; i++) {
      if (logoImages.includes(data.allFile.edges[i].node.name)) {
        logosArray.push(<Image fluid={data.allFile.edges[i] && data.allFile.edges[i].node.childImageSharp.fluid} />);
      }
    }
  }
  return (
    <div className="paymentLogos">
      {logosArray}
    </div>
  )
}

export default ComponentName

