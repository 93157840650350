import styled from "styled-components"

export const FlagsWrapper = styled.section`
  #dropdown-selector{
    cursor: pointer;
    user-select: none;
    &::after{
      content: '';
      border: solid;
      border-color: #55b9a3;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 4px;
      transform: rotate(45deg);
      margin-left: 10px;
      margin-bottom: 10px;
    }
    &.arrow-up{
      &::after{
        transform: rotate(-135deg);
        margin-bottom: 4px;
      }
    }
    .country-name{
      font-size: 16px;
      position: relative;
      bottom: 8px;
      color: rgba(255, 255, 255, 0.75);
    }
  }
  #flags-container{
      width: 300px;
      max-width: 80%;
      height: 260px;
      position: absolute;
      border-radius: 4px;
      display: none;
      &.show-list{
        display: block;
      }
      #flags-container-inner{
        overflow-y: scroll;
        max-height: 100%;
        .flag-link div{
          &:hover{
            background: #E9ECEF;
          }
          .flag-img{
            top: 4px;
          }
          .country-name{
            font-size: 16px;
            position: relative;
            bottom: 6px;
            color: #144056;
          }
        }
      }
  }
`
