import styled from "styled-components"

export const EconomyWrapper = styled.section`
    .app{
        .row{
            .mixed-chart{
                margin-top: 20px;
            }
        }
    }
`
