import Layout from "../components/Global/Layout/Layout"
import SEO from "../components/Global/SEO/seo"
import HeroSection from "../components/CountryReportsFocus/HeroSection/HeroSection"
import Population from "../components/CountryReportsFocus/Population/Population"
import Ecommerce from "../components/CountryReportsFocus/Ecommerce/Ecommerce"
import PaymentPreferences from "../components/CountryReportsFocus/PaymentPreferences/PaymentPreferences"
import Economy from "../components/CountryReportsFocus/Economy/Economy"
import InternetPhoneTrends from "../components/CountryReportsFocus/InternetPhoneTrends/InternetPhoneTrends"
import Banking from "../components/CountryReportsFocus/Banking/Banking"
import SignUpForNewsLetter from "../components/CountryReportsFocus/SignUpForNewsLetter/SignUpForNewsLetter"
import AlternatingRows from "../components/CountryReportsFocus/AlternatingRows/AlternatingRows"
import { CountryReportsFocusStyles } from "../components/CountryReportsFocus/CountryReportsFocus.styles.js"
import {
  EmailIcon,
  EmailShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share"
import React, { useEffect, useState, Component } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { FiLink } from "react-icons/fi"
import styled from "styled-components"
import { Button, Col, Container, Row } from "react-bootstrap"
import { Link, graphql } from "gatsby"

const ShareBarStyles = styled.section`
  color: #144056;
  padding-top: 10px;
  padding-bottom: 20px;
  .share-bar-header {
    color: black;
    font-size: 0.95rem;
  }
  #copy-link-text {
    color: inherit;
  }
`

const ShareBar = ({ pageUrl, title }) => {
  const [linkCopied, setLinkCopied] = useState(false);
  useEffect(() => {
    const socialTags = ["linkedin", "twitter", "email", "copyLink"]
    const elementsToBeAddedWithId = ["svg", "rect", "path", "button", "a"]
    // fetch all elements with social Id share-button-${socialTag}
    const socialButtons = document.querySelectorAll(socialTags.map(st =>`#share-button-${st}`));
    // itereate over all social Buttons
    socialButtons.forEach((socialButton, buttonIndex) => {
      // fetch known element Tags
      elementsToBeAddedWithId.forEach(tagName => {
        const elements = socialButton.getElementsByTagName(tagName) || [];
        // set ids to them
        for (let element of elements) {
          element.setAttribute('id', `article-share-${socialTags[buttonIndex%socialTags.length]}`);
        }
      });
    })
  },[pageUrl]);
  return (
    <ShareBarStyles className="container px-4">
      <span className="share-bar-header px-2 ">SHARE ON SOCIAL MEDIA</span>
      <br />
      <LinkedinShareButton
        id="share-button-linkedin"
        className="share-buttons"
        url={pageUrl}
        title={title}
      // onClick={() => handleShareClick("article-share-linkedin")}
      >
        <LinkedinIcon
          size={40}
          bgStyle={{ fill: "transparent" }}
          iconFillColor="#090909"
        />
      </LinkedinShareButton>
      <TwitterShareButton
        id="share-button-twitter"
        className="share-buttons"
        url={pageUrl}
        title={title}
      // onClick={() => handleShareClick("article-share-twitter")}
      >
        <TwitterIcon
          size={40}
          bgStyle={{ fill: "transparent" }}
          iconFillColor="#090909"
        />
      </TwitterShareButton>
      <EmailShareButton
        id="share-button-email"
        className="share-buttons"
        url={pageUrl}
        subject={title}
      // onClick={() => handleShareClick("article-share-email")}
      >
        <EmailIcon
          size={40}
          bgStyle={{ fill: "transparent" }}
          iconFillColor="#090909"
        />
      </EmailShareButton>
      <CopyToClipboard
        id="share-button-copyLink"
        className="share-buttons"
        text={pageUrl}
        style={{ color: "#090909" }}
        onCopy={() => setLinkCopied(true)}
      // onClick={() => handleShareClick("article-copyLink")}
      >
        <Button variant="link" id="copyLink-text">
          <FiLink />
          <span id="article-share-copyLink">&nbsp; {linkCopied ? "Link Copied" : "Copy Link"}</span>
        </Button>
      </CopyToClipboard>
      <br />
      <br />
    </ShareBarStyles>
  )
}

class Country extends Component {
  render () {
    let post = this.props.data.contentfulCountryReport

    const hideMainContent = post.onlyShowCountryReportRows;
    console.log(hideMainContent);
    for(let i in post){
      if(post[i] === null){
        post[i] = false;
      }
    }

    let heroSection = ''
    if (post.title || post.description || post.countryFlag[0]) {
      heroSection =
      <HeroSection
        title={post.title}
        description={post.description.description}
        flagCode={post.countryFlag[0]}
      />
    }
    let population = ''
    if ((post.populationDescription || post.populationDataYears || post.populationDataAmount || post.populationIncrement) && !hideMainContent) {
      population =
      <Population
        description={post.populationDescription.populationDescription}
        populationDataYears={post.populationDataYears}
        populationDataAmount={post.populationDataAmount}
        populationIncrement={post.populationIncrement}
      />
    }
    let ecommerce = ''
    if ((post.eCommerceDescription || post.eCommerceDataPointTitle || post.eCommerceDataPointValue) && !hideMainContent) {
      ecommerce =
      <Ecommerce
        description={post.eCommerceDescription.eCommerceDescription}
        dataTitles={post.eCommerceDataPointTitle}
        dataValues={post.eCommerceDataPointValue}
      />
    }
    let paymentPrefrences = ''
    if ((post.paymentPreferencesDescription || post.paymentPreferencesKeys || post.paymentPreferencesLogos || post.paymentPreferencesValues) && !hideMainContent) {
      paymentPrefrences =
      <PaymentPreferences
        description={post.paymentPreferencesDescription.paymentPreferencesDescription}
        keys={post.paymentPreferencesKeys}
        logos={post.paymentPreferencesLogos}
        values={post.paymentPreferencesValues}
      />
    }
    let economy = ''
    if ((post.economyDescription || post.populationDataYears || post.economyChartValues || post.economyChartIncrement) && !hideMainContent) {
      economy =
      <Economy
        description={post.economyDescription.economyDescription}
        keys={post.populationDataYears}
        values={post.economyChartValues}
        increment={post.economyChartIncrement}
      />
    }
    let internetPhoneTrends = ''
    if ((post.internetPhoneTrendsDescription || post.internetPhoneTrendsChartLabels || post.internetPhoneTrendsChartValues) && !hideMainContent) {
      internetPhoneTrends =
      <InternetPhoneTrends
        description={post.internetPhoneTrendsDescription.internetPhoneTrendsDescription}
        labels={post.internetPhoneTrendsChartLabels}
        values={post.internetPhoneTrendsChartValues}
      />
    }
    let banking = ''
    if ((post.bankingDescription || post.bankingStatisticsTitle || post.bankingStatisticsValue) && !hideMainContent) {
      banking =
      <Banking
        description={post.bankingDescription.bankingDescription}
        dataTitles={post.bankingStatisticsTitle}
        dataValues={post.bankingStatisticsValue}
      />
    }

    let metaTitle = `Payment Gateway ${post.title}`;
    let metaDescription = `APEXX specialises in guiding enterprise merchants into new markets globally. Find out more about the payments landscape in ${post.title} by using our educational report.`;

    if (this.props.data.contentfulCountryReport.metaTitle) {
      metaTitle = this.props.data.contentfulCountryReport.metaTitle;
    }

    if (this.props.data.contentfulCountryReport.metaDescription.metaDescription) {
      metaDescription = this.props.data.contentfulCountryReport.metaDescription.metaDescription;
    }

    const pageRows = post.countryReportsRowsReference;
    
    let iteration = 0;
    let CustomRows = [];
    let rowAlternation;

    if (pageRows) {
      pageRows.forEach(function(element) {
        if (element.description) {
          if (iteration % 2 == 0) {
            rowAlternation = 'even';
          }
          else{
            rowAlternation = 'odd';
          }
          iteration++;
          CustomRows.push(<AlternatingRows rowData={element} rowAlternation={rowAlternation} />);
        }
        /* else{
          console.log(element);
          console.log('--------');
        } */
      });  
    }

    return (
      <Layout
        headerTransparent
        whiteToggler
        logoDark={false}
      >
        <CountryReportsFocusStyles>
          <SEO page="country-report" title={metaTitle} description={metaDescription} />
          {heroSection}
          {population}
          {ecommerce}
          {paymentPrefrences}
          {economy}
          {internetPhoneTrends}
          {banking}
          {CustomRows}
          {/*<HeroSection
            title={post.title}
            description={post.description.description}
            flagCode={post.countryFlag[0]}
          />
          <Population
            description={post.populationDescription.populationDescription}
            populationDataYears={post.populationDataYears}
            populationDataAmount={post.populationDataAmount}
            populationIncrement={post.populationIncrement}
          />
          <Ecommerce
            description={post.eCommerceDescription.eCommerceDescription}
            dataTitles={post.eCommerceDataPointTitle}
            dataValues={post.eCommerceDataPointValue}
          />
          <PaymentPreferences
            description={post.paymentPreferencesDescription.paymentPreferencesDescription}
            keys={post.economyChartKeys}
            logos={post.paymentPreferencesLogos}
            values={post.paymentPreferencesValues}
          />
          <Economy
            description={post.economyDescription.economyDescription}
            keys={post.populationDataYears}
            values={post.economyChartValues}
            increment={post.economyChartIncrement}
          />
          <InternetPhoneTrends
            description={post.internetPhoneTrendsDescription.internetPhoneTrendsDescription}
            labels={post.internetPhoneTrendsChartLabels}
            values={post.internetPhoneTrendsChartValues}
          />
          <Banking
            description={post.bankingDescription.bankingDescription}
            dataTitles={post.bankingStatisticsTitle}
            dataValues={post.bankingStatisticsValue}
          /> */}
          <section className="px-4">
            <div className="pt-3 border-top d-flex flex-column align-items-center container"></div>
          </section>
          {/* <ShareBar pageUrl={'pageUrl'} title={'title'} /> */}
          <SignUpForNewsLetter />
        </CountryReportsFocusStyles>
      </Layout>
    )
  }
}

export default Country


export const query = graphql`
  query getpostAndGetpostAndGetpostAndGetpost($slug: String) {
    contentfulCountryReport(slug: { eq: $slug }) {
      title
      slug
      metaTitle
      metaDescription{
        metaDescription
      }
      countryFlag
      description {
        description
      }
      populationDescription {
        populationDescription
      }
      populationDataAmount
      populationDataYears
      populationIncrement
      eCommerceDescription {
        eCommerceDescription
      }
      eCommerceDataPointTitle
      eCommerceDataPointValue
      paymentPreferencesDescription {
        paymentPreferencesDescription
      }
      paymentPreferencesKeys
      paymentPreferencesLogos
      paymentPreferencesValues
      economyChartIncrement
      economyChartKeys
      economyChartValues
      economyDescription {
        economyDescription
      }
      internetPhoneTrendsChartLabels
      internetPhoneTrendsChartValues
      internetPhoneTrendsDescription {
        internetPhoneTrendsDescription
      }
      bankingDescription {
          bankingDescription
      }
      bankingStatisticsTitle
      bankingStatisticsValue
      onlyShowCountryReportRows
      countryReportsRowsReference {
        title
        rowBackgroundColour
        description {
          json
        }
        image {
          fluid {
            ...GatsbyContentfulFluid
          }
          title
        }
      }
    }
  }
  `
