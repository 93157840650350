import React, { Component } from "react";
import { PopulationWrapper } from "./Population.styles"
import { Container, Row, Col } from "react-bootstrap"

import loadable from '@loadable/component'

const ColumnChart = loadable(() => import("./ColumnChart"))

const Population = ({ description, populationDataYears, populationDataAmount, populationIncrement }) => {
  return (
    <PopulationWrapper className="py-3 py-sm-5 px-4 px-sm-5">
      <Container className="py-5">
        <Row>
          <Col lg={8} className="text-left pb-5 pb-lg-3 my-auto pl-lg-0 px-0">
            <h2 className="h1 font-weight-bold secondary-blue-2-text mb-4">
              Population
            </h2>
            <p>
            { description }
            </p>
          </Col>
          <Col lg={4} className="my-auto pr-lg-0 px-0">
            { populationDataYears || populationDataAmount || populationIncrement
              ? <ColumnChart height="290" categories={populationDataYears} values={populationDataAmount} increment={populationIncrement}/>
              : <div></div>
            }
          </Col>
        </Row>
      </Container>
    </PopulationWrapper>
  )
}

export default Population
