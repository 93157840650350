import styled from "styled-components"

export const BankingWrapper = styled.section`
.dataContainer{
    @media (min-width: 992px){
        display: inline-grid;
        display: -ms-inline-grid;
    }
    .dataPoint{
        .dataValue{
            font-family: Nunito Sans;
            font-weight: 800;
            font-size: 56px;
            line-height: 56px;
            color: #27B2BD;
            @media (max-width: 575px){
                font-size: 50px;
                line-height: 50px;
            }
        }
        .dataTitle{
            font-family: Poppins;
            font-size: 18px;
            line-height: 28px;
            color: #144056;
            @media (max-width: 575px){
                font-size: 16px;
            }
        }
        @media only screen and (max-width: 991px){
            max-width: 50%;
        }
        @media only screen and (max-width: 575px){
            width: 100%;
            max-width: 100%;
        }
    }
}
`
