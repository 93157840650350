import styled from "styled-components"

export const PaymentPreferencesWrapper = styled.section`
    background-color: #3B5CA7;

    .donutChartContainer{
        max-width: 450px;
        .chartContainer{
            height: 0;
            width: 100%;
            padding-top: 100%;
            .donutChart{
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                .apexcharts-inner{
                    transform: scale(1.3);
                }
            }
            .chartTitle {
                text-align: center;
                position: absolute;
                color: #fff;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-family: 'Poppins', 'Nunito Sans';
                font-size: 20px;
                line-height: 19px;
                min-width: 180px;
                @media (max-width: 992px){
                    top: 52%;
                }
                @media (max-width: 575px){
                    font-size: 19px;
                }
                @media (max-width: 468px){
                    top: 51%;
                }
                @media (max-width: 368px){
                    top: 50%;
                    font-size: 18px;
                    line-height: 18px;
                    width: 70%;
                }
            }
        }
    }

    .legendLabels{
        color: #fff;
        list-style-type: none;
        li{
            cursor: default;
            font-family: 'Poppins', 'Nunito Sans';
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 8px;
            span{
                position: relative;
                display: inline-block;
                width: 20px;
                height: 20px;
                border-radius: 4px;
                margin-right: 15px;
                top: 3px;
            }
        }
    }

    .paymentLogos{
        .gatsby-image-wrapper {
            width: 115px;
            display: inline-block;
            margin-right: 10px;
            @media (max-width: 575px){
                width: 85px;
            }
        }
    }
`
