import React from "react";
import { PaymentPreferencesWrapper } from "./PaymentPreferences.styles"
import PaymentLogo from "./PaymentLogo"
import { Container, Row, Col } from "react-bootstrap"

import loadable from '@loadable/component'


const DonutChart = loadable(() => import("./DonutChart"))

const PaymentPreferences = ({description, keys, logos, values}) => {
return (
<PaymentPreferencesWrapper className="py-3 py-sm-5 px-4 px-sm-5 ">
  <Container className="py-5">
    <Row>
      <Col lg={8} className="text-left px-0 pb-5 pb-lg-3 m-auto">
      <h2 className="h1 font-weight-bold secondary-blue-2-text mb-4 nunito">
        Payment preferences
      </h2>
      <p className="text-white">
      { description }
      </p>
      <div className="d-none d-lg-block">
        <PaymentLogo logoImages={logos} />
      </div>
      </Col>
      <Col lg={4} className="m-auto px-0">
          {keys && values
            ? <DonutChart labels={keys} series={values}/>
            : <div></div>
          }
          <div className="d-block d-lg-none pt-4 text-center text-md-left">
            <PaymentLogo logoImages={logos} />
          </div>
      </Col>
    </Row>
  </Container>
</PaymentPreferencesWrapper>
)
}

export default PaymentPreferences
