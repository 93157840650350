import styled from "styled-components"

export const CountryReportsFocusStyles = styled.section`
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 28px;
  line-height: 1.75rem;
  @media (max-width: 575px){
    font-size: 16px;
    font-size: 1rem;
    line-height: 24px;
  }
  .h1{
    font-size: 2.8125rem;
  }
  .row{
    margin-left: 0;
    margin-right: 0;
  }
`
