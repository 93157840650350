import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { AlternatingRowsWrapper } from "./AlternatingRows.styles"
import ApButton from "../../Global/Common/ApButton/ApButton"
import Image from "gatsby-image"
import {documentToReactComponents} from "@contentful/rich-text-react-renderer"

const AlternatingRows = ({rowData, rowAlternation}) => {

  const title = rowData.title;
  const backgroundColor = rowData.rowBackgroundColour;
  const description = documentToReactComponents(rowData.description.json)
  const linkName = rowData.buttonLinkTitle;
  const linkLocation = rowData.buttonLinkLocation;
  const image = rowData.image;

  let bgColor = 'bg-row-green';
  let buttonColor = 'primary';
  let textColor = 'row-text-white';
  let padding1 = 'pr-lg-5';
  let padding2 = 'pl-lg-0';
  let flexDirection = '';
  let linkedButton;

  if (backgroundColor == 'Blue') {
    buttonColor = 'greenTwo';
    bgColor = 'bg-row-blue';
  }
  
  if (linkName && linkLocation) {
    linkedButton = 
    <a href={linkLocation} target="_blank">
      <ApButton
        variant={buttonColor}
        className="explore-features px-4 nunito"
        role="link"
      >
        {linkName}
      </ApButton>
    </a>;
  }

 
  if (backgroundColor == 'White') {
    textColor = 'text-dark-green';
    bgColor = 'bg-row-white';
  }
  if (backgroundColor == 'Grey') {
    textColor = 'text-dark-green';
    bgColor = 'bg-row-grey';
  }

  if (rowAlternation == 'even') {
    padding1 = 'pl-lg-5';
    padding2 = 'pr-lg-0';
    flexDirection = 'flex-row-reverse';
  }

  let imageContainer;
  let copyContentColCount = 12;

  if (image) {
    imageContainer = (
        <Col
          lg={4}
          md={8}
          className={padding2 + " m-auto"}
        >
          <Image fluid={image.fluid} alt={image.title} />
        </Col>
    );
    copyContentColCount = 8;
  }
  
  return (
    <AlternatingRowsWrapper className={"py-5 px-4 px-sm-5 " + bgColor}>
      <Container className="py-5 px-0">
        <Row className={textColor + ' text-lg-left text-md-center text-left justify-content-center ' + flexDirection}>
          <Col lg={copyContentColCount} className={"" + padding1}>
            <h2 className="h1 font-weight-bold mb-4 secondary-blue-2-text nunito">
              {title}
            </h2>
            <p>
              {description}
            </p>
            {linkedButton}
          </Col>
          {imageContainer}
        </Row>
      </Container>
    </AlternatingRowsWrapper>
  )
}

export default AlternatingRows
