import React from "react";
import { InternetPhoneTrendsWrapper } from "./InternetPhoneTrends.styles"
import { Container, Row, Col } from "react-bootstrap"

import loadable from '@loadable/component'


const RadialChart = loadable(() => import("./RadialChart"))


const InternetPhoneTrends = ({description, labels, values}) => {
return (
<InternetPhoneTrendsWrapper className="py-3 py-sm-5 px-4 px-sm-5 ">
  <Container className="py-5">
    <Row>
      <Col xs={12} className="text-xl-center text-left pb-5 pb-lg-3 px-0">
      <h2 className="text-xl-center text-left h1 font-weight-bold secondary-blue-2-text mb-4 nunito">
        Internet & mobile phone trends
      </h2>
      <p className="text-white">
      { description }
      </p>
      </Col>
      <Col lg={12} md={10} xs={12} className="mx-auto mt-5 px-0">
        <Row>
          {
            labels.map((label, i) => {
              if(label && values[i]){
                return <Col xl={3} md={6} xs={12} className="px-0 mx-auto"><RadialChart label={label} value={values[i]} counter={i}/></Col>
              }
              else{
                return '';
              }
            })
          }
        </Row>
      </Col>

    </Row>
  </Container>
</InternetPhoneTrendsWrapper>
)
}

export default InternetPhoneTrends
