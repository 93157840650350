import React from "react"
import { BankingWrapper } from "./Banking.styles"
import { Container, Row, Col } from "react-bootstrap"

const Banking = ({ description, dataTitles, dataValues }) => {
return (
<BankingWrapper className="py-3 py-sm-5 px-4 px-sm-5 ">
  <Container className="py-5">
    <Row>
      <Col lg={8} className="text-left pr-lg-3 px-0 pb-5 pb-lg-3">
      <h2 className="h1 font-weight-bold secondary-blue-2-text mb-4 nunito">
        Banking
      </h2>
      <p>
      { description }
      </p>
      </Col>
      <Col lg={4} className="my-auto text-right pl-lg-3 px-0">
        <div className="dataContainer text-center">
          {
            dataTitles.map((dataTitle, i) => {
              if (dataTitle && dataValues[i]){
                if (i % 2 === 0) {
                  var addClasses = 'float-sm-left px-0 pl-sm-4 pr-sm-2 px-lg-0';
                }
                else{
                  var addClasses = 'float-sm-right px-0 pr-sm-4 pl-sm-2 px-lg-0';
                }
                return <div className={"dataPoint mb-4 pb-2 d-sm-inline-block " + addClasses}>
                  <div class="dataValue">{dataValues[i]}</div>
                  <div class="dataTitle">{dataTitle}</div>
                </div>
              }
              else{
                return '';
              }
            })
          }
        </div>
      </Col>
    </Row>
  </Container>
</BankingWrapper>
)
}

export default Banking
