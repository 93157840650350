import { Link, graphql, useStaticQuery } from "gatsby"

import { FlagsWrapper } from "./Flags.styles"
import Img from "gatsby-image"
import React, { useState, useEffect } from 'react';

const Flags = ({ flagCode }) => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: {relativeDirectory: {eq: "country_report/country_flags"}},
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fixed(width: 30, height: 30, cropFocus: CENTER) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
      allContentfulCountryReport(
        sort: {fields: countryName, order: ASC}
        ) {
        edges {
          node {
            countryName
            countryFlag
            slug
          }
        }
      }
    }
  `)
  var countyReports = data.allContentfulCountryReport.edges;
  const images = data.allFile.edges


    function ShowList() {
    const toggle = document.getElementById('flags-container');
    const toggle2 = document.getElementById('dropdown-selector');
      if (toggle.classList.contains('hide-list')) {
        toggle.classList.remove('hide-list')
        toggle.classList.add('show-list')
        toggle2.classList.add('arrow-up');
      }
      else if (toggle.classList.contains('show-list')) {
        toggle.classList.remove('show-list')
        toggle.classList.add('hide-list')
        toggle2.classList.remove('arrow-up');
      }
    }

  return (
    <FlagsWrapper className="secondary-blue-3-background mb-3">

      {countyReports.map((countyReport, i) => {
        if (countyReport.node.countryFlag[0] == flagCode) {
        const firstImage = images.find(
          (image) => image.node.name === countyReport.node.countryFlag[0]
        ).node.childImageSharp.fixed
        return (
          <div className="d-inline-block" id="dropdown-selector" onClick={ShowList}>
            <Img fixed={firstImage} className="flag-img rounded-circle d-inline-block" alt={countyReport.node.countryName + ' Flag'}/>
            <div className="country-name d-inline-block ml-3">
              {countyReport.node.countryName}
            </div>
          </div>
        )
        }
        })}
        <div className="white-background py-4 pl-3 pr-4 hide-list" id="flags-container">
          <div id="flags-container-inner">
            {countyReports.map((countyReport, i) => {
              if (countyReport.node.countryFlag[0] != flagCode) {
                const fixed = images.find(
                  (image) => image.node.name === countyReport.node.countryFlag[0]
                ).node.childImageSharp.fixed
                return (
                <Link
                    className="flag-link"
                    to={`/country-report/${countyReport.node.slug}/`}
                    target="_blank"
                >
                  <div className="py-1">
                    <Img fixed={fixed} className="flag-img rounded-circle d-inline-block ml-3" alt={countyReport.node.countryName + ' Flag'}/>
                    <div className="country-name d-inline-block ml-3">
                      {countyReport.node.countryName}
                    </div>
                  </div>
                </Link>
                )
              }
              })}
            </div>
        </div>
    </FlagsWrapper>
  )
}

export default Flags
