import styled from "styled-components"

export const AlternatingRowsWrapper = styled.section`
    background-color: #144056;
    &.bg-row-blue{
        background-color: #0F6CB6;
    }
    &.bg-row-white{
        background-color: #FFFFFF;
    }
    &.bg-row-grey{
        background-color: #F4F4F4;
    }
    h2{
        font-size: 45px;
    }
    .container{
        max-width: 1170px;
    }
    .text-dark-green{
        color: #144056;
    }
    .row-text-white{
        color: #FFF;
        p{
            color: #FFF;
        }
        a{
            color: #55baa3;
            &:hover{
                color: #42a38d;
            }
        }
    }
    button.btn-greenTwo{
        border-radius: 15px;
    }
`
