import React from "react"
import { Col, Container, Jumbotron, Row } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import { HeroWrapper } from "./HeroSectionStyles"
import Img from "gatsby-image"
import Flags from "./flags/Flags"

const HeroSection = ( { title, description, flagCode } ) => {

  return (
    <HeroWrapper className="py-5 px-4 px-sm-5 position-relative secondary-blue-3-background">
      <Jumbotron className="secondary-blue-3-background rounded-0 mb-0 header-jumbotron text-left px-0">

        <Container className="pb-5 hero-text">
          {flagCode
            ? <Flags flagCode={flagCode}/>
            : <div></div>
          }
          <h2 className="h1 blue-aqua font-weight-bold py-lg-3 nunito">{ title }</h2>
          <p className="text-white">
            { description }
          </p>
        </Container>
      </Jumbotron>
    </HeroWrapper>
  )
}

export default HeroSection
