import React, { Component } from "react";
import { EconomyWrapper } from "./Economy.styles"
import { Container, Row, Col } from "react-bootstrap"

import loadable from '@loadable/component'

const ColumnChart = loadable(() => import("./ColumnChart"))

const Economy = ({description, keys, values, increment}) => {
return (
<EconomyWrapper className="py-3 py-sm-5 px-4 px-sm-5">
  <Container className="py-5">
    <Row>
      <Col lg={8} className="text-lg-left pb-5 pb-lg-3 my-auto pr-lg-3 px-0">
      <h2 className="h1 font-weight-bold secondary-blue-2-text mb-4">
        Economy
      </h2>
      <p>
        { description }
      </p>
      </Col>
      <Col lg={4} className="my-auto pl-lg-3 px-0">
        {keys && values && increment
          ? <ColumnChart height="290" keys={keys} values={values} increment={increment} />
          : <div></div>
        }

      </Col>
    </Row>
  </Container>
</EconomyWrapper>
)
}

export default Economy
