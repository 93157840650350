import styled from "styled-components"

export const PopulationWrapper = styled.section`
  background-color: #f4f4f4;
  .app{
    .row{
      .mixed-chart{
        margin-top: 20px;
      }
    }
  }
`
